<template>
    <v-card elevation="0" class="mb-5">
        <v-row>
            <v-col>
                <v-divider></v-divider>
            </v-col>
        </v-row>

      <h4 class="mt-5">Payments</h4>
      <v-card-text class="px-0">
        <!--Amount owed-->
        <v-alert v-if="payments.length > 0 && amountOutstanding > 0" icon="mdi-alert-circle" outlined>
            <span class="text-orange">{{ `${formatAsCurrency('R', amountOutstanding)}`}}</span> still owing... ({{ `${formatAsCurrency('R', totalPayments)} paid of ${formatAsCurrency('R', salesOrder.order_value)}.` }})
        </v-alert>
        <!--Paid in full-->
        <v-alert v-else-if="payments.length > 0 && totalPayments === salesOrder.order_value" outlined type="success" icon="mdi-check-circle">
          Paid in full.
        </v-alert>
        <!--Overpaid-->
        <v-alert v-else-if="payments.length > 0 && totalPayments > salesOrder.order_value" outlined type="error" icon="mdi-alert-circle">
          {{ `The order has been overpaid by ${formatAsCurrency('R', totalPayments - Number(salesOrder.order_value))}` }}
        </v-alert>
        <v-list dense>
          <!-- No payments alert -->
          <v-alert outlined color="grey" icon="mdi-alert-circle" v-if="payments.length === 0">No payments added</v-alert>
          
          <!-- Payments list -->
          <v-list-item
            v-for="(payment, index) in payments"
            :key="payment.id"
          >
            <v-list-item-content>
              <v-row align="center" class="half-width" no-gutters>
                <v-col cols="1">{{ index + 1 }}.</v-col>
                <v-col cols="3">{{ payment.date }}</v-col>
                <v-col cols="3">{{ payment.type }}</v-col>
                <v-col cols="2" class="text-right">{{ formatAsCurrency("R", payment.amount) }}</v-col>
                <v-col cols="2" offset="1">
                  <v-btn icon small @click="editPayment(payment, index)">
                    <v-icon small color="blue">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon small @click="deletePayment(index)">
                    <v-icon small color="red">mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="px-0">
        <v-btn color="primary" class="px-3" @click="showPaymentDialog = true">
            <v-icon small left>mdi-plus</v-icon> Add Payment
        </v-btn>
      </v-card-actions>
      <!-- Payments dialog -->
      <v-dialog v-model="showPaymentDialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="mb-5">{{ editMode ? 'Edit Payment' : 'Add a New Payment' }}</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="4">
                  <!-- Payment Date -->
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="newPayment.date"
                    transition="scale-transition"
                    offset-y
                    min-width="200px"
                    :rules="[rules.required]"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        v-model="newPayment.date"
                        label="Payment Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="newPayment.date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.menu.save(newPayment.date)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <!-- Payment Type Selector -->
                  <v-select
                    outlined
                    dense
                    v-model="newPayment.type"
                    :items="paymentTypes"
                    label="Payment Type"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <!-- Payment Amount -->
                  <v-text-field
                    outlined
                    dense
                    v-model.number="newPayment.amount"
                    label="Amount"
                    type="number"
                    prefix="R"
                    :rules="[rules.required, rules.positiveNumber]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="cancelEdit">Cancel</v-btn>
            <v-btn color="green darken-1" :disabled="!valid" text @click="editMode ? updatePayment() : addPayment()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showPaymentDialog: false,
        editMode: false,
        editIndex: -1,
        newPayment: {
            date: new Date().toISOString().substr(0, 10),
            type: null,
            amount: null,
        },
        paymentTypes: ["EFT", "Card", "Cash", "E-commerce"],
        menu: false,
        valid: false,
        rules: {
          required: value => !!value || 'Required.',
          positiveNumber: value => (value > 0) || 'Must be greater than zero.'
        }
      };
    },
    computed: {
        totalPayments() {
            return this.payments.reduce((sum, payment) => sum + Number(payment.amount), 0);
        },
        amountOutstanding() {
          return this.salesOrder.order_value - this.totalPayments;
        }
    },
    methods: {
        addPayment() {
            this.payments.push({...this.newPayment, id: this.payments.length + 1});
            this.$emit('update:payments', this.payments);
            this.resetPaymentForm();
        },
        editPayment(payment, index) {
            this.newPayment = {...payment};
            this.showPaymentDialog = true;
            this.editMode = true;
            this.editIndex = index;
        },
        updatePayment() {
            this.payments.splice(this.editIndex, 1, {...this.newPayment});
            this.$emit('update:payments', this.payments);
            this.resetPaymentForm();
        },
        deletePayment(index) {
            if(!confirm("Are you sure you want to delete this payment?")) return;
            this.payments.splice(index, 1);
            this.$emit('update:payments', this.payments);
        },
        cancelEdit() {
            this.resetPaymentForm();
        },
        resetPaymentForm() {
            this.showPaymentDialog = false;
            this.editMode = false;
            this.editIndex = -1;
            this.newPayment = { date: new Date().toISOString().substr(0, 10), type: null, amount: null };
        },
        formatAsCurrency (currency, inputVal) {
            return `${currency} ${new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(inputVal)}`
        }
    },
    props: {
      salesOrder: Object,
      payments: Array,
    }
  };
  </script>
  
  <style scoped>
  .half-width {
    max-width: 70%;
  }
  .text-orange {
    color: orange;
    font-weight: bold;
  }
  </style>
  